import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import cn from "../../utils/cn";
import { env } from "../../utils/env";

// React icons
import { ImSpinner8 } from "react-icons/im";

// Check environment variables
let EMAILJS_TEMP_ID;
let EMAILJS_SERVICE_ID;

if (env.NODE_ENV === "development") {
  EMAILJS_TEMP_ID = env.REACT_APP_EMAILJS_DEV_TEMP_ID;
  EMAILJS_SERVICE_ID = env.REACT_APP_EMAILJS_DEV_SERVICE_ID;
} else {
  EMAILJS_TEMP_ID = env.REACT_APP_EMAILJS_PROD_TEMP_ID;
  EMAILJS_SERVICE_ID = env.REACT_APP_EMAILJS_PROD_SERVICE_ID;
}

const EMAILJS_SECRET_KEY = env.REACT_APP_EMAILJS_SECRET_KEY;
const RECAPTCHA_SITE_KEY = env.REACT_APP_RECAPTCHA_SITE_KEY;
const Form = ({}) => {
  const form = useRef();
  const [nameValid, setNameValid] = useState(true);
  const [companyValid, setCompanyValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [mobileValid, setMobileValid] = useState(true);
  const [entityValid, setEntityValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);
  const [validateData, setValidateData] = useState({
    isNameValid: true,
    isCompanyValid: true,
    isEmailValid: true,
    isMobileValid: true,
    isEntityValid: true,
    isCaptchaValid: true,
    isMessageValid: true,
  });
  const [submitStatus, setSubmitStatus] = useState({
    status: "idel",
    message: "",
  });

  const [entityArray, setEntityArray] = useState([
    {
      entity: "MSME",
      selected: false,
      entityId: "msme",
    },
    {
      entity: "Lending Institution",
      selected: false,
      entityId: "lendingInstitution",
    },
    {
      entity: "Other",
      selected: false,
      entityId: "other",
    },
  ]);

  const validateName = (name) => {
    const isValid = name?.length > 3 && /^[A-Za-z ]*$/.test(name);
    setValidateData((prevState) => ({
      ...prevState,
      isNameValid: isValid,
    }));
    return isValid;
  };

  const validateEmail = (email) => {
    const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      email
    );
    setValidateData((prevState) => ({ ...prevState, isEmailValid: isValid }));
    return isValid;
  };

  const validateMobile = (mobile) => {
    const isValid =
      mobile?.length > 0 && /^(?:\+91|91)?[6789]\d{9}$/.test(mobile);
    setValidateData((prevState) => ({
      ...prevState,
      isMobileValid: isValid,
    }));
    return isValid;
  };

  const validateCaptcha = (entity) => {
    // Implement your entity validation logic
    const isValid = entity;
    setValidateData((prevState) => ({
      ...prevState,
      isCaptchaValid: isValid,
    }));
    return isValid;
  };
  const validateMessage = (entity) => {
    // Implement your entity validation logic
    const isValid = entity?.length > 5;
    setValidateData((prevState) => ({
      ...prevState,
      isMessageValid: isValid,
    }));
    return isValid;
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitStatus({
      status: "submitting",
      message: "Submitting form...",
    });

    const isNameValid = validateName(nameValid);
    const isEmailValid = validateEmail(emailValid);
    const isMobileValid = validateMobile(mobileValid);
    const isCaptchaValid = validateCaptcha(verifyCaptcha);
    const isMessageValid = validateMessage(messageValid);
    if (
      isNameValid &&
      isEmailValid &&
      isMobileValid &&
      isCaptchaValid &&
      isMessageValid
    ) {
      emailjs
        .sendForm(
          EMAILJS_SERVICE_ID,
          EMAILJS_TEMP_ID,
          form.current,
          EMAILJS_SECRET_KEY
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              setSubmitStatus({
                status: "submitted",
                message: "Form submitted Successfully!",
              });
            }
          },
          (error) => {
            console.log(error.text);
            setSubmitStatus({
              status: "error",
              error: true,
              message: "Failed to submit form. Please try again.",
            });
          }
        );
    } else {
      setTimeout(() => {
        setSubmitStatus({
          status: "ideal",
          message: "",
        });
      }, 1000);
    }
  };
  const handleEntityInput = (selectedEntity) => {
    setEntityArray((prevArray) =>
      prevArray.map((entity) => ({
        ...entity,
        selected: entity.entity === selectedEntity,
      }))
    );
  };
  return (
    <div className="flex w-full flex-col items-center justify-center md:my-0">
      <div className="relative flex w-full flex-col md:flex-row">
        <div className="hidden  flex-1 items-center justify-center bg-[radial-gradient(487.93%_127.1%_at_32.59%_-16.31%,#BC49C4_0%,#692CCD_100%)] py-12 xl:flex xl:justify-end">
          <div className="flex max-w-[642px] items-center justify-center xl:justify-end">
            <img
              className="max-w-3xl"
              src="/img/illustrations/contact.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-center gap-10 bg-tertiary px-6 py-32">
          <div className="max-w-[642px]">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-3">
                <h3 className="rounded-b-md rounded-t-3xl py-3 text-4xl font-black md:text-6xl md:leading-[1.5]">
                  Ready to begin an exciting journey?
                </h3>
                <h5 className=" text-base md:text-xl">
                  You can reach us anytime via
                  <a
                    className="pl-[5px] text-quaternary "
                    href="mailto:joincensorblack@gmail.com"
                  >
                    joincensorblack@gmail.com
                  </a>
                </h5>
              </div>
              <p className="mb-8 text-xs text-gray-900 md:text-sm">
                Field marked with
                <span className="relative mx-1 mr-3 text-base text-red-600">
                  <p className="absolute bottom-0 left-0 right-0 top-1">*</p>
                </span>
                is mandatory
              </p>
            </div>
            <form
              className="flex w-full flex-col justify-between gap-8 md:gap-10"
              ref={form}
            >
              <div className="flex w-full flex-col justify-between gap-8 sm:flex-row">
                <div className="relative flex w-full flex-1 flex-col gap-1">
                  <label className="flex font-medium" htmlFor="">
                    Full Name
                    <span className="relative mx-0.5 mr-2 text-red-600">*</span>
                  </label>
                  <input
                    className={cn(
                      !nameValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="full_name"
                    type="text"
                    placeholder="John Doe"
                    pattern="^[a-zA-Z]{3,20}$"
                    onChange={(e) => {
                      setNameValid(e.target.value);
                      validateName(e.target.value);
                    }}
                    required={true}
                  />
                  {!validateData.isNameValid && (
                    <p className="absolute -bottom-6 text-red-600">
                      Invalid name
                    </p>
                  )}
                </div>
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Company
                  </label>
                  <input
                    className={cn(
                      !companyValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="company_name"
                    type="text"
                    onChange={(e) => setCompanyValid(e.target.value)}
                    placeholder="XYZ Pvt. Ltd."
                    pattern="^[a-zA-Z]{3,20}$"
                    required
                  />
                </div>
              </div>
              <div className="flex w-full flex-col justify-between gap-8 sm:flex-row">
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Email
                    <span className="relative mx-0.5 mr-2 text-red-600">*</span>
                  </label>
                  <input
                    className={cn(
                      !emailValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="user_email"
                    type="email"
                    placeholder="example@mail.com"
                    onChange={(e) => {
                      setEmailValid(e.target.value);
                      validateEmail(e.target.value);
                    }}
                    pattern="^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                    required
                  />
                  {!validateData.isEmailValid && (
                    <p className="absolute -bottom-6 text-red-600">
                      Invalid email
                    </p>
                  )}
                </div>
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Mobile No.
                    <span className="relative mx-0.5 mr-2 text-red-600">*</span>
                  </label>
                  <input
                    className={cn(
                      !mobileValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="mobile_number"
                    type="number"
                    onChange={(e) => {
                      setMobileValid(e.target.value);
                      validateMobile(e.target.value);
                    }}
                    placeholder="+91 9876543210"
                    pattern="[0-9]{10}"
                    required
                  />
                  {!validateData.isMobileValid && (
                    <p className="absolute -bottom-6 text-red-600">
                      Invalid mobile number
                    </p>
                  )}
                </div>
              </div>
              <div>
                <h3 className={cn("mb-3 font-medium ")}>Select entity type</h3>
                <div className="flex flex-col flex-wrap gap-4">
                  <div className="s flex flex-col gap-10 whitespace-nowrap md:flex-row">
                    {entityArray.map((entity, i) => {
                      return (
                        <div className="flex items-center gap-6" key={i}>
                          <input
                            className={cn(
                              !entityValid && "border-[1px] border-purple-600",
                              "h-12 w-6 px-4"
                            )}
                            name={`${entity.entityId}`}
                            type="radio"
                            onChange={() => handleEntityInput(entity.entity)}
                            checked={entity.selected}
                          />
                          <label className="text-lg" htmlFor="">
                            {entity.entity}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col flex-wrap justify-between gap-8 sm:flex-row">
                <div className="relative flex w-full flex-col gap-1 whitespace-nowrap">
                  <label className="font-medium" htmlFor="">
                    Message
                    <span className="relative mx-0.5 mr-2 text-red-600">*</span>
                  </label>
                  <textarea
                    className={cn(
                      !messageValid && " border-red-500",
                      "w-full rounded-md border-[1.5px] p-4 duration-500"
                    )}
                    name="message"
                    rows="5"
                    type="text"
                    onChange={(e) => {
                      setMessageValid(e.target.value);
                      validateMessage(e.target.value);
                    }}
                    placeholder="Tell us what you want to say..."
                    required
                  />
                  {!validateData.isMessageValid && (
                    <p className="absolute -bottom-6 text-red-600">
                      Invalid message
                    </p>
                  )}
                </div>
              </div>
              <div className="relative flex flex-1 gap-1">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={() => {
                    setVerifyCaptcha(true);
                    validateData.isCaptchaValid = true;
                  }}
                  onExpired={() => {
                    setVerifyCaptcha(false);
                  }}
                />
                {!validateData.isCaptchaValid && (
                  <p className="absolute -bottom-6 text-red-600">
                    Captcha hasn’t been verified.
                  </p>
                )}
              </div>

              <button
                // disabled={
                //   !nameValid ||
                //   !companyValid ||
                //   !emailValid ||
                //   !mobileValid ||
                //   !entityValid ||
                //   submitStatus.status === "submitting"
                // }
                onClick={sendEmail}
                className={cn(
                  submitDisabled &&
                    "flex items-center justify-center bg-gray-400  bg-[image:none]",
                  "rounded-md bg-[radial-gradient(487.93%_127.1%_at_32.59%_-16.31%,#BC49C4_0%,#692CCD_100%)] px-6 py-3 font-semibold text-white sm:text-lg"
                )}
              >
                {submitStatus.status === "submitting" ? (
                  <ImSpinner8 className="animate-spin text-lg font-extrabold" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
            <div className="my-6 w-full text-center">
              <p className=" font-medium text-red-600">
                {submitStatus.status === "error" && submitStatus.message}
              </p>

              <p className="font-medium text-purple-600">
                {submitStatus.status === "submitted" && submitStatus.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
