import React, { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import cn from "../utils/cn";

const Navbar = ({ page }) => {
  const [open, setOpen] = useState(false);
  const [navBg, setNavBg] = useState(false);
  const [backdropBlur, setBackdropBlur] = useState(true);
  const [currentPage, setCurrentPage] = useState("home");

  const learnModalRef = useRef();
  const [learnIsOpen, setLearnIsOpen] = useState(false);
  const [visibleArrow, setVisibleArrow] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 10) {
        setNavBg(true);
      } else setNavBg(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const learnModalHandler = () => {
    setLearnIsOpen(!learnIsOpen);
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (learnModalRef.current && !learnModalRef.current.contains(e.target)) {
        setLearnIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);
  return (
    <nav
      className={cn(
        "fixed z-[99999] flex h-16 w-full items-center justify-center shadow-md md:backdrop-blur-3xl",
        page === "home" ||
          page === "contact" ||
          page === "terms-and-conditions" ||
          page === "privacy-policy"
          ? "text-black"
          : "text-white",
        open
          ? "shadow-none md:shadow-[rgba(0,0,0,0.35)_0px_5px_15px]"
          : window.scrollY >= 10
          ? "shadow-[rgba(0,0,0,0.35)_0px_5px_15px]"
          : "",
        backdropBlur ? "backdrop-blur-3xl" : ""
      )}
      id="navbar"
    >
      <div className="flex w-full justify-center">
        <div className="w-full max-w-[1284px]">
          <div className="flex h-full items-center justify-between px-4 min-[480px]:px-8">
            {page === "solution" ? (
              <NavLink to="/" className="flex items-center justify-center">
                <img
                  src="/img/logo/logoLight.webp"
                  className={cn(
                    open ? "max-[768px]:hidden" : "block",
                    "scale-120 rounded-none"
                  )}
                  alt=""
                />
              </NavLink>
            ) : (
              <NavLink
                to="/"
                className="flex items-center justify-center gap-2"
              >
                <img
                  src="/favicon.png"
                  className={cn(
                    open ? "max-[768px]:hidden" : "block",
                    "scale-120 rounded-none"
                  )}
                  alt=""
                />
                <img
                  src="/img/logo/logoDark.png"
                  className={cn(
                    open ? "max-[768px]:hidden" : "block",
                    "scale-120 mt-[6px] rounded-none"
                  )}
                  alt=""
                />
              </NavLink>
            )}
            <span
              className="z-20 cursor-pointer md:hidden"
              onClick={() => {
                setOpen(!open);
                setBackdropBlur(!backdropBlur);
              }}
            >
              {open ? (
                <IoCloseSharp className="h-7 w-7" />
              ) : (
                <GiHamburgerMenu className="h-7 w-7" />
              )}
            </span>
            <div
              className={`
                                fixed right-0 top-0 z-10 h-screen w-full flex-row items-center border-[rgba(255,255,255,0.06)] pl-12 pt-28 backdrop-blur-3xl duration-300 ease-linear min-[420px]:w-[85%] md:static md:flex md:h-auto md:w-auto md:gap-12 md:pl-0 md:pt-0 md:backdrop-blur-none
                                ${
                                  open
                                    ? "right-0 border-l-[1px] shadow-[rgba(0,0,0,0.35)_0px_5px_15px] md:border-none md:shadow-none"
                                    : "right-[-100%] backdrop-blur-none"
                                }
                            `}
            >
              <NavLink
                to="/"
                className={cn(
                  location.pathname === "/"
                    ? "border-[color:var(--secondary-color)]"
                    : "border-transparent",
                  "relative flex max-w-max cursor-pointer items-center border-b-4 text-lg transition-all hover:border-[color:var(--secondary-color)] md:my-0"
                )}
                onClick={() => setCurrentPage("Home")}
              >
                <span className="absolute -left-5 -top-6 bottom-0 text-5xl font-bold opacity-10 md:static md:mr-1 md:hidden md:text-lg">
                  01
                </span>
                <span className={location.pathname === "/" && "font-semibold"}>
                  Home
                </span>
              </NavLink>
              <div
                ref={learnModalRef}
                className="group relative my-20 md:my-0 md:flex md:flex-col md:items-center"
                onClick={learnModalHandler}
              >
                <NavLink
                  to="/solution"
                  className={cn(
                    location.pathname === "/solution"
                      ? "border-[color:var(--secondary-color)]"
                      : "border-transparent",
                    "relative flex max-w-max cursor-pointer items-center self-end border-b-4 text-lg transition-all hover:border-[color:var(--secondary-color)] md:my-0"
                  )}
                  onClick={() => setCurrentPage("Solution")}
                >
                  <span className="absolute -left-5 -top-6 bottom-0 text-5xl font-bold opacity-10 md:static md:mr-1 md:hidden md:text-lg">
                    02
                  </span>
                  <span
                    className={
                      location.pathname === "/solution" && "font-semibold"
                    }
                  >
                    Solution
                  </span>
                </NavLink>
              </div>
              <NavLink
                to="/contact"
                className={cn(
                  location.pathname === "/contact"
                    ? "border-[color:var(--secondary-color)]"
                    : "border-transparent",
                  "relative flex max-w-max cursor-pointer items-center border-b-4 text-lg transition-all hover:border-[color:var(--secondary-color)] md:my-0"
                )}
                onClick={() => setCurrentPage("Contact")}
              >
                <span className="absolute -left-5 -top-6 bottom-0 text-5xl font-bold opacity-10 md:static md:mr-1 md:hidden md:text-lg">
                  03
                </span>
                <span
                  className={
                    location.pathname === "/contact" && "font-semibold"
                  }
                >
                  Contact
                </span>
              </NavLink>
              {/* {page === "contact" ? null : (
                <a
                  href={page === "home" ? "#contactSection" : "/contact"}
                  className={cn(
                    "relative mt-12 flex max-w-max cursor-pointer items-center rounded-md border-2 border-secondaryLight bg-secondaryLight/20 py-[6px] px-3 text-lg transition-all duration-300 hover:border-transparent hover:bg-secondary hover:text-white  md:my-0"
                  )}
                >
                  <span className="font-medium">Get Started</span>
                </a>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
