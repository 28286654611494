import React from "react";

const Backers = () => {
  return (
    <div>
      {" "}
      <div className="relative z-10 my-20 flex px-6 before:absolute before:bottom-80 before:left-0 before:right-0 before:top-64 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:min-w-[calc(100vh_-_60px)] md:justify-center md:text-center">
        <div className="flex max-h-max max-w-[1284px] flex-col gap-20 md:items-center md:justify-center">
          <div className="flex w-full  md:text-center md:text-inherit">
            <h3 className="relative z-10 mt-16 w-full text-center text-4xl font-black leading-7 text-primary md:text-6xl md:leading-[2] lg:leading-[1.25]">
              Meet our backers
            </h3>
          </div>
          <div className="flex w-full flex-col gap-10">
            <div className="flex flex-col justify-around gap-12 lg:flex-row">
              <div className="flex-[1/2) hidden flex-col items-center justify-start gap-10 rounded-xl bg-transparent px-[64px] py-10 md:flex ">
                <div className="flex w-full justify-center">
                  <div className="hidden h-64 w-60 rounded-full bg-purple-600">
                    <img
                      src=""
                      className="h-48 w-48 md:h-52 md:w-52 xl:h-64 xl:w-64"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center gap-4 text-center">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold">
                      {/* Mr. Animesh Jha  */}
                    </h4>
                    <h4 className="text-2xl font-semibold text-secondary">
                      {/* VP - Wibmo (PayU)  */}
                    </h4>
                  </div>
                  <p className="hidden w-3/4 text-xl opacity-70">
                    22 years of experience in software industry technical and
                    business expertise in fintech has worked extensively on FRM,
                    Anti-financial crimes solutions an expert in AI, Blockchain
                    and cloud technologies. 
                  </p>
                </div>
              </div>
              <div className="flex flex-1 flex-col items-center justify-start gap-10 rounded-xl bg-white p-10 shadow-xl">
                <div className="flex w-full justify-center">
                  {/* <div className="w-60 flex h-64 bg-purple-600 rounded-full"> */}
                  <img
                    src="/img/backers/mrAnimesh.png"
                    className="h-48 w-48 md:h-52 md:w-52 xl:h-64 xl:w-64"
                    alt=""
                  />
                  {/* </div> */}
                </div>
                <div className="flex flex-col items-center gap-4 text-center">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold">Mr. Animesh Jha </h4>
                    <h4 className="text-xl font-semibold text-secondary">
                      VP - Wibmo (PayU) 
                    </h4>
                  </div>
                  <p className="w-3/4 text-xl opacity-70">
                    {" "}
                    22 years of experience in software industry technical and
                    business expertise in fintech has worked extensively on FRM,
                    Anti-financial crimes solutions an expert in AI, Blockchain
                    and cloud technologies. 
                  </p>
                </div>
              </div>
              <div className="flex  flex-1 flex-col items-center justify-start gap-10 rounded-xl bg-white p-10 shadow-xl">
                <div className="flex w-full justify-center">
                  {/* <div className="h-52 w-52 md:h-64 md:w-60 flex bg-purple-600 rounded-full"> */}
                  <img
                    src="/img/backers/nikunjSir.png"
                    className="h-48 w-48 md:h-52 md:w-52 xl:h-64 xl:w-64"
                    alt=""
                  />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold">Mr. Nikunj Dang</h4>
                    <h4 className="text-xl font-semibold text-secondary">
                      MD - Accenture Strategy 
                    </h4>
                  </div>
                  <p className="text-xl opacity-70">
                    Nikunj is a Accenture Strategy's Managing Director, offers
                    20+ years in banking consulting.
                  </p>
                </div>
              </div>
              <div className="hidden flex-[1/2] flex-col items-center justify-start gap-10 rounded-xl bg-transparent px-[64px] py-10 md:flex ">
                <div className="flex w-full justify-center"></div>
                <div className="flex flex-col items-center gap-4 text-center">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold text-primary"></h4>
                    <h4 className="text-2xl font-semibold"></h4>
                  </div>
                  <p className="w-3/4 text-xl opacity-70"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backers;
