import { cleanEnv, str } from "envalid";

export const env = cleanEnv(process.env, {
  NODE_ENV: str({ default: "development" }),

  // EmailJS development template and service ID
  REACT_APP_EMAILJS_DEV_TEMP_ID: str({ default: "na" }),
  REACT_APP_EMAILJS_DEV_SERVICE_ID: str({ default: "na" }),

  // EmailJS production template and service ID
  REACT_APP_EMAILJS_PROD_TEMP_ID: str(),
  REACT_APP_EMAILJS_PROD_SERVICE_ID: str(),

  // EmailJS secret key
  REACT_APP_EMAILJS_SECRET_KEY: str(),

  // Google reCAPTCHA v2 key
  REACT_APP_RECAPTCHA_SITE_KEY: str(),
});
