import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Footer from "../components/Footer.jsx";
import Navbar from "../components/Navbar.jsx";
import { LuMoveLeft } from "react-icons/lu";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { PageLoader } from "../components/PageLoader.jsx";
import { useNavigate } from "react-router-dom";

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

const maxWidth = "1024";

const TermsAndConditions = () => {
  const [file, setFile] = useState("/terms-and-conditions.pdf");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  const navigate = useNavigate();

  const onResize = useCallback((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div className=" bg-[color:var(--bg-primary)] font-[Montserrat] text-white">
      <div className="relative">
        <Navbar page="terms-and-conditions" />
        <div className="fixed top-16 z-50 ms-[8vw] mt-3  w-full bg-transparent">
          <div onClick={() => navigate(-1)} className="cursor-pointer">
            <LuMoveLeft className="text-xl text-black sm:text-2xl " />
          </div>
        </div>
        <div
          className="Example__container__document pt-10"
          ref={setContainerRef}
        >
          <Document
            file="/docs/terms-and-conditions.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            className="flex w-full flex-col justify-center"
            loading={
              <div className="!h-[200vh] bg-white">
                <PageLoader />
              </div>
            }
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="flex w-full justify-center"
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
              />
            ))}
          </Document>
        </div>
        <Footer page="terms-and-conditions" />
      </div>
    </div>
  );
};

export default TermsAndConditions;
