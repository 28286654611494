import { useRef } from "react";
import Footer from "../components/Footer.jsx";
import Form from "../components/Form/Form.jsx";
import Navbar from "../components/Navbar.jsx";

const Contact = () => {
  const form = useRef();

  return (
    <div className="min-h-screen  font-[Montserrat]">
      <div className="relative">
        <Navbar page="contact" />
        <div className=" flex min-h-[calc(100vh)] w-full justify-center">
          <div className="flex min-h-full w-full flex-col items-center justify-center max-[480px]:text-left">
            {/* <FormContextProvider> */}
            <Form page="contact" />
            {/* </FormContextProvider> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
