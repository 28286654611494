import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.scss";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Solution from "./pages/Solution";
import TermsAndConditions from "./pages/TermsAndConditions";

// const router = new createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/contact",
//     element: <Contact />,
//   },
//   {
//     path: "/solution",
//     element: <Solution />,
//   },
//   {
//     path: "/terms-and-conditions",
//     element: <TermsAndConditions />,
//   },
//   {
//     path: "/privacy-policy",
//     element: <PrivacyPolicy />,
//   },
// ]);

const Routes = () => {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "solution",
          element: <Solution />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
      ],
    },
  ]);
};
function App() {
  return (
    <BrowserRouter>
      <div className="relative">
        {/* <RouterProvider router={router} /> */}
        <Routes />
      </div>
    </BrowserRouter>
  );
}

export default App;
