import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex  items-center justify-center bg-primaryDark p-10 text-white">
      <div className="flex max-w-[1284px] flex-col ">
        <div className="flex flex-col items-start justify-between gap-12 self-stretch md:flex-row">
          <div className=" flex flex-col gap-10">
            {/* <div className="w-20 h-12"> */}
            <div className="">
              <img
                className="h-full w-40 rounded-none"
                src="/img/logo/logoLight.webp"
                alt="EquiDEI logo"
              />
            </div>
            <p className="opacity-90 md:w-4/12">
              Censor Black Pvt. Ltd. 8-2-644/1/205 F.No. 205, Hiline Complex,
              Road No. 12, Banjara Hills Hyderabad500034 Telangana. Telangana
            </p>
          </div>
          <div className="flex justify-between gap-12">
            <div className="flex flex-col gap-5">
              <h3 className="font-semibold">Product</h3>
              <ul className=" flex flex-col gap-3">
                <Link to="/" className="opacity-90">
                  Home
                </Link>
                <Link to="/solution" className="flex gap-3">
                  <span className="opacity-80">Solution</span>
                  <span className="flex items-center rounded-full border-[1px] border-secondary p-[2px_8px] text-xs">
                    New
                  </span>
                </Link>
                <Link to="/contact" className="opacity-90">
                  Contact
                </Link>
              </ul>
            </div>
            <div className="flex flex-col gap-5">
              <h3 className="font-semibold">Social</h3>
              <ul className=" flex flex-col gap-3">
                <Link
                  to="https://www.linkedin.com/company/equidei/"
                  className="opacity-90"
                  target="_blank"
                >
                  LinkedIn
                </Link>
                <Link
                  to="https://twitter.com/Equidei_eth"
                  className="opacity-90"
                  target="_blank"
                >
                  Twitter
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-10 flex flex-col-reverse gap-8 border-t-[1px] pt-5 md:flex-row md:justify-between">
          <p>
            © {new Date().getFullYear()} Censor Black Private Limited. All
            rights reserved.
          </p>
          <div className="flex gap-10">
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
