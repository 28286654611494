import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

// {
//   index && (
//     <span className="absolute top-0 text-6xl font-bold left-2 opacity-10">
//       0{index}
//     </span>
//   )
// }

const Card = ({
  type,
  heading,
  paragraph1,
  paragraph2,
  className,
  index,
  headingClasses,
  button,
  card,
}) => {
  const [upArrowVisible, setUpArrowVisible] = useState(false);
  const [rangeValue, setRangeValue] = useState("₹10,000,00");
  const [rangeValueNumber, setRangeValueNumber] = useState(0);

  const rangeHandler = (e) => {
    setRangeValue(e.target.value);
    if (e.target.value === "0") {
      setRangeValue("₹10,000,00");
      setRangeValueNumber(0);
    } else if (e.target.value === "1") {
      setRangeValue("₹20,000,00");
      setRangeValueNumber(1);
    } else if (e.target.value === "2") {
      setRangeValue("₹30,000,00");
      setRangeValueNumber(2);
    } else if (e.target.value === "3") {
      setRangeValue("₹40,000,00");
      setRangeValueNumber(3);
    } else if (e.target.value === "4") {
      setRangeValue("₹50,000,00");
      setRangeValueNumber(4);
    } else if (e.target.value === "5") {
      setRangeValue("₹60,000,00");
      setRangeValueNumber(5);
    } else if (e.target.value === "6") {
      setRangeValue("₹70,000,00");
      setRangeValueNumber(6);
    } else if (e.target.value === "7") {
      setRangeValue("₹80,000,00");
      setRangeValueNumber(7);
    } else if (e.target.value === "8") {
      setRangeValue("₹90,000,00");
      setRangeValueNumber(8);
    } else {
      setRangeValue("₹1,00,000,00");
      setRangeValueNumber(9);
    }
  };

  return (
    <div className={["relative flex-col rounded-3xl", className].join(" ")}>
      {heading && (
        <h4
          className={`w-full rounded-tl-3xl rounded-tr-3xl px-5 py-5 font-bold md:text-center ${headingClasses}`}
        >
          {heading}
        </h4>
      )}
      <div className="flex flex-col items-center p-6">
        {!upArrowVisible && paragraph1 && (
          <p className="text-lg opacity-80 md:pb-16 md:text-center md:text-xl">
            {paragraph1}
          </p>
        )}
        {upArrowVisible &&
          (card === "integration" ? (
            <div className="flex flex-col items-start gap-3 pb-16 text-lg opacity-80 lg:text-xl">
              <p>
                Our data source integration works with three data source
                categories:
              </p>
              <div className="flex flex-col gap-1">
                <p> 1) Internal data sources of the MSME,</p>
                <p>2) External data sources pertaining to the borrowers and</p>
                <p>
                  3) Data obtained through market research We ensure data
                  authenticity, feeding validated info to TES Analytics Engine.
                  Our approach excels in curated, secure derivation.
                </p>
              </div>
            </div>
          ) : (
            <p className="pb-16 text-center text-lg opacity-80 lg:text-xl">
              {paragraph2}
            </p>
          ))}
        {upArrowVisible && type === "range" && (
          <div className="flex flex-col items-center">
            <div>
              <span>Asset Value</span>
              <h5>{rangeValue}</h5>
              <input
                type="range"
                min="0"
                max="9"
                value={rangeValueNumber}
                className="slider"
                id="myRange"
                onChange={rangeHandler}
              />
            </div>
            <p>Move the slider to adjust your asset value</p>
            <div>
              <span>Credit Limit</span>
              <h5>₹10,000,00</h5>
            </div>
            <p>Your credit limit is increased by this amount</p>
          </div>
        )}
        {button && (
          <button
            className="mt-5 flex items-center justify-center gap-1 md:mt-0"
            onClick={() => setUpArrowVisible((prev) => !prev)}
          >
            <p className="">{!upArrowVisible ? "Learn More" : "Close"}</p>
            {!upArrowVisible ? (
              <span className="">
                <IoIosArrowDown className="" />
              </span>
            ) : (
              <span className="">
                <IoIosArrowUp className="" />
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Card;
