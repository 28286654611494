import React from "react";

const Team = () => {
  return (
    <div className="relative z-10 flex justify-center rounded-3xl bg-[#0000000A] px-6 py-40 text-center before:absolute before:bottom-80 before:left-0 before:right-0 before:top-64 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:min-w-[calc(100vh_-_60px)]">
      <div className="flex max-h-max max-w-[1284px] flex-col justify-center gap-20 md:items-center">
        <div className="flex w-full xl:w-[1084px] xl:max-w-[1084px]">
          <h3 className="relative z-10 w-full text-4xl font-black leading-7 text-primary md:text-6xl md:leading-[2] lg:leading-[1.25]">
            Meet our Founder
          </h3>
        </div>

        <div className="flex w-full flex-col gap-10 lg:flex-row">
          <div className="hidden flex-[1/2] flex-col items-center justify-start gap-10 rounded-xl bg-transparent px-[20px] py-10 lg:flex min-[1390px]:px-[64px] ">
            <div className="flex w-full justify-center"></div>
            <div className="flex flex-col items-center gap-4 text-center">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold text-primary"></h4>
                <h4 className="text-2xl font-semibold"></h4>
              </div>
              <p className="w-3/4 text-xl opacity-70"></p>
            </div>
          </div>
          <div className="flex flex-1 items-center  justify-center gap-10 rounded-xl bg-white p-10 shadow-xl max-[1210px]:flex-col">
            <img
              src="/img/team/raghav.png"
              className="h-52 w-52 md:h-64 md:w-64"
              alt=""
            />
            <div className="flex flex-[2] flex-col gap-4 !text-left max-[1210px]:!text-center">
              <div className="flex items-center gap-5 max-[1210px]:flex-col">
                <div className="flex flex-col gap-[-1rem]">
                  <h4 className="text-2xl font-bold">Raghav Gupta</h4>
                  <h4 className="text-xl font-semibold text-secondary">
                    Founder
                  </h4>
                </div>
                <img
                  src="/img/team/logo/vitlogo.png"
                  className="shrink-0 object-contain"
                ></img>
              </div>
              {/* <p className="opacity-70 lg:w-3/4 text-xl"> */}
              <div>
                <p className="text-xl opacity-70">Industrial engineer </p>
                <p className="text-xl opacity-70">
                  Demostrated experience working with state of the art
                  technologies DLT, AI and IOT based solution
                </p>
              </div>
              <p className="text-xl opacity-70">
                Raghav is a NIDHI PRAYAS Innovator and a winner of SIIC
                Fellowship and MEITY TIDE EIR
              </p>
              <div className="mt-5 flex flex-wrap items-center gap-4 max-[1210px]:!justify-center">
                <div className="flex shrink-0 basis-16 flex-col items-center justify-center">
                  <img
                    src="/img/team/logo/IIT_Kanpur_Logo.png"
                    className="h-[80px] w-[90px] shrink-0 object-contain"
                  ></img>
                  <div className="mt-1 text-center text-[8px] font-medium opacity-70">
                    IIT kanpur
                  </div>
                </div>
                <div className="h-[100px] border border-black"></div>
                <div className="flex shrink-0 basis-16 flex-col items-center justify-center">
                  <img
                    src="/img/team/logo/siicLogo.png"
                    className="h-[70px] w-[90px] shrink-0 "
                  ></img>
                  <div className="mt-1 text-center text-[8px] font-medium opacity-70">
                    Incubation and Innovation
                  </div>
                </div>
                <div className="h-[100px] border border-black"></div>
                <div className="shrink-0 basis-16 text-[12px] opacity-70">
                  <div className="font-semibold">STARTUP</div>
                  <div className="font-semibold">INCUBATION AND</div>
                  <div className="font-semibold">INNOVATION</div>
                  <div className="font-semibold">CENTRE</div>
                  <div>IIT KANPUR</div>
                </div>
                <img
                  src="/img/team/logo/DST-NIDHI.png"
                  className="h-[90px] w-[80px] shrink-0 basis-16 "
                ></img>
                <img
                  src="/img/team/logo/IIM-CALCUTTA.png"
                  className="h-[90px] w-[80px] shrink-0 basis-16 "
                ></img>
                <img
                  src="/img/team/logo/MEITY-STARTUP.png"
                  className="h-[90px] w-[90px] shrink-0 basis-16"
                ></img>
              </div>
            </div>
          </div>
          <div className="hidden flex-[1/2] flex-col items-center justify-start gap-10 rounded-xl bg-transparent px-[20px] py-10 lg:flex min-[1390px]:px-[64px] ">
            <div className="flex w-full justify-center"></div>
            <div className="flex flex-col items-center gap-4 text-center">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold text-primary"></h4>
                <h4 className="text-2xl font-semibold"></h4>
              </div>
              <p className="w-3/4 text-xl opacity-70"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
