import React, { useRef } from "react";
import { BsArrowDown } from "react-icons/bs";
import { MdFactory } from "react-icons/md";
import { RiBankLine } from "react-icons/ri";

import { Link } from "react-router-dom";
import Advisors from "../components/Advisors.jsx";
import Backers from "../components/Backers.jsx";
import Footer from "../components/Footer.jsx";
import Form from "../components/Form/Form.jsx";
import Navbar from "../components/Navbar.jsx";
import Team from "../components/Team.jsx";

const Home = () => {
  const contactFormRef = useRef();
  const getStartedScrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="min-h-screen bg-background text-black">
      <div className="relative">
        <Navbar page="home" />
        <div className="flex items-center justify-center max-[480px]:text-left">
          <div className="w-full">
            <div className="relative flex min-h-[calc(100svh_-_64px)] flex-col items-center justify-center  gap-24 px-6 py-48 min-[480px]:px-12 sm:mt-16 sm:h-auto sm:min-h-0 md:min-h-0 md:flex-row">
              <div className="flex max-w-[1284px] flex-col items-center justify-center gap-12  md:flex-row md:gap-10">
                <div className="flex flex-col gap-20 lg:flex-row lg:gap-12">
                  <div className=" flex flex-1 flex-col gap-8">
                    <h1 className="flex flex-col items-start gap-3 text-4xl font-black tracking-tight text-primary xs:text-5xl  md:px-0 md:text-6xl md:leading-[3.5rem] md:tracking-normal">
                      <span className="">Monetising Factories.</span>
                      <span className="">Generating Wealth.</span>
                    </h1>
                    <p className="font- flex flex-col gap-4 text-lg md:gap-10 md:text-xl">
                      Seamlessly transforming businesses into thriving revenue
                      streams while fostering wealth creation across industries.
                      Reshaping the future of industrial finance through
                      innovative digitization.
                    </p>
                    <a
                      href="#contact-section"
                      className="w-max rounded-md bg-secondary px-8 py-4 font-medium text-white"
                    >
                      Get Started
                    </a>
                  </div>
                  <div className=" w-full flex-1">
                    <div className="flex w-full justify-center sm:w-full">
                      <img
                        src="/img/illustrations/hero.png"
                        className="object-cover"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className=" hidden justify-center px-4">
                  <button className="absolute bottom-1 left-0 right-0 flex justify-center">
                    <BsArrowDown className="animation-duration animate-bounce stroke-[1] text-5xl" />
                  </button>
                </div>
              </div>
            </div>

            {/* Tabs */}
            <div className="relative flex items-center justify-center bg-primary px-6 py-16 md:w-full md:py-48">
              <div className="flex max-h-max max-w-[1284px] flex-col gap-20">
                <div className="flex w-full md:text-center">
                  <h3 className="relative z-10 mt-8 w-full text-left text-4xl font-bold leading-10 text-tertiary xs:text-5xl sm:text-center md:mt-0 md:text-6xl md:leading-[2] lg:leading-[1.25]">
                    One Network, Infinite Possibilities
                  </h3>
                </div>
                <div className="flex flex-col-reverse gap-20 md:gap-12">
                  <div className="flex w-full flex-row-reverse items-center gap-10 text-white">
                    <div className="flex flex-[1]  flex-col gap-6">
                      <h2 className="flex items-center gap-3 text-xl sm:text-3xl">
                        <div className="rounded-full bg-secondary/30 p-2 backdrop-blur-[1px]">
                          <div className="rounded-full bg-secondary/50 p-2 backdrop-blur-[1px]">
                            <MdFactory className="" />
                          </div>
                        </div>
                        <span className="font-bold">
                          Micro Small & Medium Enterprises
                        </span>
                      </h2>
                      <div className="flex flex-col gap-2">
                        <h4 className="text-lg font-semibold sm:text-2xl">
                          Empowering MSME’s Growth
                        </h4>
                        <p className="font-light sm:text-xl">
                          Discover a world of possibilities tailored to fuel
                          your MSME's success. EQUIDEI accelerates your business
                          with business asset monetization, and real-time
                          management analytics. Streamline operations, harness
                          the potential of your assets and chart a course toward
                          sustainable growth and prosperity.
                        </p>
                      </div>
                    </div>
                    <div className="hidden flex-1 ms:flex">
                      <img src="/img/illustrations/msme3d.png" alt="" />
                    </div>
                  </div>
                  <div className="flex w-full items-center gap-10 text-white">
                    <div className="flex flex-[1] flex-col gap-6">
                      <h2 className="flex items-center gap-3 text-3xl">
                        <div className="rounded-full bg-secondary/30 p-2 backdrop-blur-[1px]">
                          <div className="rounded-full bg-secondary/50 p-2 backdrop-blur-[1px]">
                            <RiBankLine className="" />
                          </div>
                        </div>
                        <span className="font-bold">Financial Institution</span>
                      </h2>
                      <div className="flex flex-col gap-2">
                        <h4 className="text-lg font-semibold sm:text-2xl">
                          Invest with Confidence
                        </h4>
                        <p className=" font-light sm:text-xl">
                          With EQUIDEI, you gain access to Real Time, Authentic
                          and Verifiable credit reports. This is powered via our
                          proprietary asset monetisation based on shared
                          ownership models. Make informed investment decisions
                          backed by robust data, while contributing to the
                          growth and expansion of thriving Industries.
                        </p>
                      </div>
                      <Link
                        to="/solution"
                        className="w-max rounded-md bg-tertiary px-5 py-2 text-primary sm:px-8 sm:py-3 sm:font-bold"
                      >
                        Know More
                      </Link>
                    </div>
                    <div className="hidden flex-1 ms:flex">
                      <img src="/img/illustrations/fi3d.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Digital Secure Revolutionary */}
            <div className="relative z-10 items-center justify-center px-6 py-20 md:flex md:py-40">
              <div className="flex w-full max-w-[1284px] flex-col items-center justify-center md:flex-row">
                <div className="flex w-full max-w-[1084px] flex-col gap-8 max-[480px]:gap-6 max-[480px]:text-left xl:w-[1084px]">
                  <h3 className="relative text-4xl font-black  text-primary xs:pl-0  md:text-6xl md:leading-[2] lg:leading-[1.25]">
                    Digital. Secure. Revolutionary.
                  </h3>
                  <p className="text-lg font-light opacity-80 sm:text-xl">
                    We leverage the power of blockchain technology to build a
                    more equitable, diverse and inclusive ecosystem.
                  </p>
                </div>
                <div className="flex w-full flex-col md:items-center md:justify-center">
                  <img
                    src="/img/illustrations/main3.png"
                    className="mt-12 hidden w-3/6 xs:block md:w-3/6"
                    alt=""
                  />
                  {/* <img src={Main3} className="xs:hidden" alt="" /> */}
                  <p className="z-[-1] -mt-20 h-28 w-12 rotate-[80deg] bg-[radial-gradient(487.93%_127.10%_at_32.59%_-16.31%,rgba(188,73,196,0.80)_0%,rgba(105,_44,205,0.80)_100%)] blur-2xl xs:-mt-36 xs:h-64 xs:w-12 xs:blur-3xl"></p>
                </div>
              </div>
            </div>

            {/* CONTACT FORM */}
            {/* <FormContextProvider> */}
            <div
              className="flex w-full flex-col items-center justify-center max-[480px]:text-left"
              ref={contactFormRef}
              id="contact-section"
            >
              <Form page="contact" />
            </div>
            {/* </FormContextProvider> */}

            {/* TEAM */}
            <Team />

            {/* ADVISORS */}
            <Advisors />

            {/* BACKERS */}
            <Backers />

            {/* FOOTER */}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
